/**
 * Dev environment
 *
 * Put what you like in it, but DON'T use it to build prod !
 */
export const environment = {
  production: false,
  title: 'Weezchat, tchat illimité',
  api: 'https://chat-api-new-pp.contactdve.com/api',
  chat: 'https://pp-chat-liveengine.contactdve.com/',

  waitingAPI: 'dating-notification-pp.dv-content.io/',
  waitingToken: 'TtD4dFtMCfu92eCK',
  extCode: 'TN',
  gtm: 'GTM-M3HVR36',
  gtmCountry: 'GTM-KVBXZJQ',
  completeProfileInputs: ['phoneNumber'],
  id: '85b395a6-dd1a-4996-a449-69474e354ecd',
  dvp4m: false,
  gsv: null,

  appLink: null,
  didomi: null,
};
